

const ipAddress = "https://4b3nyvn344.execute-api.us-east-2.amazonaws.com/atherton"


const returnUrl = "https://www.application.athertonmarketing.com"

export function getServerIp(){
    return ipAddress
}

export function getReturnUrl(){
    return returnUrl
}